import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import "./gov_agencies.scss";

const AddGovAgencies = (props) => {
  const [govagencies, addgovagencies] = useState({});
  // const [isloading, setisloading] = useState(false);

  const type = (e) => {
    const ud = Object.assign({}, govagencies);
    ud.type = e.target.value;
    addgovagencies(ud);
  };
  const name_al = (e) => {
    const ud = Object.assign({}, govagencies);
    ud.name_al = e.target.value;
    addgovagencies(ud);
  };
  const name_sr = (e) => {
    const ud = Object.assign({}, govagencies);
    ud.name_sr = e.target.value;
    addgovagencies(ud);
  };
  const name_en = (e) => {
    const ud = Object.assign({}, govagencies);
    ud.name_en = e.target.value;
    addgovagencies(ud);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("gov-agencies", govagencies)
      .then((data) => {
        // console.log(data);
        toast.success("Agency has been added successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <div className="govagencies p-5 text-left">
      <div className="page-title">
        <h1>Add Government Agency</h1>
      </div>
      {/* {!isloading ? '' : */}
      <form onSubmit={handleSubmit}>
        <select onChange={type}>
          <option value={null}>Select Type</option>
          <option value={"National"}>National</option>
          <option value={"Local"}>Local</option>
        </select>

        <input
          type="text"
          placeholder="Name Albanian"
          onChange={name_al}
          required
          title="This field is required"
        ></input>

        <input
          type="text"
          required
          title="This field is required"
          placeholder="Name Serbian"
          onChange={name_sr}
        ></input>

        <input
          type="text"
          required
          title="This field is required"
          placeholder="Name English"
          onChange={name_en}
        ></input>

        <button type="submit">Add Agency</button>
      </form>
      {/* } */}
    </div>
  );
};
export default AddGovAgencies;
