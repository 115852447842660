import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../loader/Loader";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const Delete = (props) => {
  const [misionivizoni, deletemisionivizioni] = useState({});
  const [languages, editlanguages] = useState("al");
  const [isloading, setisloading] = useState(true);

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      editlanguages("al");
    }
    if (lang === "sr") {
      editlanguages("sr");
    }
    if (lang === "en") {
      editlanguages("en");
    }
  };

  useEffect(() => {
    axios
      .get("misionvision/id?id=" + props.match.params.id)
      .then((data) => {
        deletemisionivizioni(data?.data);
        // console.log("userdata", data?.data);
        // setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("misionvision/id?id=" + misionivizoni.id)
      .then((data) => {
        // console.log(data?.data);
        window.history.back();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="misioni_vizoni mt-2 text-left ">
      <div className="page-title">
        <h1>Delete Mission and Vision</h1>
      </div>
      {!isloading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="row ml-2 p-1 mb-5">
            <a
              className={languages == "al" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("al")}
            >
              Albanian
            </a>
            <a
              className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("sr")}
            >
              Serbian
            </a>
            <a
              className={languages == "en" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("en")}
            >
              English
            </a>
          </div>

          {languages == "al" && (
            <>
              <textarea
                placeholder="Përmbajtja"
                defaultValue={misionivizoni?.content_al}
                disabled
              />
              {/* <ReactQuill
                theme="snow"
                defaultValue={misionivizoni?.content_al}
                formats={formats}
                modules={modules}
                disable
              /> */}
            </>
          )}
          {languages == "sr" && (
            <>
              <textarea
                placeholder="Përmbajtja"
                defaultValue={misionivizoni?.content_sr}
                disabled
              />
              {/* <ReactQuill
                theme="snow"
                defaultValue={misionivizoni?.content_sr}
                formats={formats}
                modules={modules}
                disable
              /> */}
            </>
          )}
          {languages == "en" && (
            <>
              <textarea
                placeholder="Përmbajtja"
                defaultValue={misionivizoni?.content_en}
                disabled
              />
              {/* <ReactQuill
                theme="snow"
                defaultValue={misionivizoni?.content_en}
                formats={formats}
                modules={modules}
                disable
              /> */}
            </>
          )}
          {/* <input type="date" onChange={createdAt} required /> */}

          <button type="submit">Delete</button>
        </form>
      )}
    </div>
  );
};

export default Delete;
