import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";

const AddLabelTranslations = (props) => {
  const [labeltranslations, addlabeltranslations] = useState({});
  const [userroles, adduserroles] = useState({});
  const [languages, addlanguages] = useState({});
  const [isloading, setisloading] = useState(false);

  const type = (e) => {
    const ud = Object.assign({}, labeltranslations);
    ud.type = e.target.value;
    addlabeltranslations(ud);
    // console.log('seeme',labeltranslations)
  };
  const key = (e) => {
    const ud = Object.assign({}, labeltranslations);
    ud.key = e.target.value;
    addlabeltranslations(ud);
    // console.log('seeme',labeltranslations)
  };
  const content_al = (e) => {
    const ud = Object.assign({}, labeltranslations);
    ud.content_al = e.target.value;
    addlabeltranslations(ud);
    // console.log('seeme',labeltranslations)
  };
  const content_sr = (e) => {
    // console.log(e)
    const ud = Object.assign({}, labeltranslations);
    ud.content_sr = e.target.value;
    addlabeltranslations(ud);
    // console.log('seeme',labeltranslations)
  };
  const content_en = (e) => {
    // console.log(e)
    const ud = Object.assign({}, labeltranslations);
    ud.content_en = e.target.value;
    addlabeltranslations(ud);
    // console.log('seeme',labeltranslations)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("labels-translations", labeltranslations)
      .then((data) => {
        // console.log(data);
        toast.success("Label has been added successfully!");
        window.history.back();
      })
      .catch((err) => {
        // toast.error('Something went wrong!')
        console.log(err);
      });
  };
  // console.log(userroles);
  // console.log(languages);

  return (
    <div className="labels_translations p-5 text-left">
      <div className="page-title">
        <h1>Add Label Translation</h1>
      </div>
      {/* {!isloading ? '' : */}
      <form onSubmit={handleSubmit}>
        <select onChange={type}>
          <option value={null}>Select Type</option>
          <option value={"Mobile"}>Mobile</option>
          <option value={"Web"}>Web</option>
        </select>
        <input
          type="text"
          required
          title="This field is required"
          placeholder="Key"
          onChange={key}
        ></input>
        <input
          type="text"
          placeholder="Name Albanian"
          onChange={content_al}
          required
          title="This field is required"
        ></input>

        <input
          type="text"
          required
          title="This field is required"
          placeholder="Name Serbian"
          onChange={content_sr}
        ></input>
        <input
          type="text"
          required
          title="This field is required"
          placeholder="Name English"
          onChange={content_en}
        ></input>

        <button type="submit">Add Label</button>
      </form>
      {/* } */}
    </div>
  );
};
export default AddLabelTranslations;
