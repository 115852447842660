import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";

const DeleteNews = (props) => {
  const [news, deleteNews] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [languages, editlanguages] = useState("al");

  useEffect(() => {
    axios
      .get("users/all")
      .then((data) => setUsersData(data?.data))
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
    axios
      .get("news/id?id=" + props.match.params.id)
      .then((data) => {
        deleteNews(data?.data);
        // console.log("userdata", data?.data);
        // setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("news/?id=" + news.id)
      .then((data) => {
        // console.log(data?.data);
        window.history.back();
      })
      .catch((err) => console.log(err));
  };
  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      editlanguages("al");
    }
    if (lang === "sr") {
      editlanguages("sr");
    }
    if (lang === "en") {
      editlanguages("en");
    }
  };


  console.log("usersData", news)
  return (
    <div className="news p-5 text-left">
      <div className="page-title">
        <h1>Delete News</h1>
      </div>
      <div className="row mb-3 text">
        By clicking delete button you agree to delete news with the following
        data:
      </div>
      {/* {!isloading ? (
        ""
      ) : ( */}
      <form onSubmit={handleSubmit}>
        {/* <input type="file" defaultValue={news.pic_url} disabled /> */}
        {/* <label>From date:</label> */}

        {/* <div className="row p-3">
          <img
            src={`${process.env.REACT_APP_UPLOADS}${news.pic_url}`}
            alt="News Image"
            width="50px"
          />
        </div> */}
        <input
          type="date"
          defaultValue={news?.news_date?.slice(0, 10)}
          disabled
        />
        {/* <label>To date:</label> */}
        <input
          type="date"
          defaultValue={news?.created_at?.slice(0, 10)}
          disabled
        />

  

        <div className="row ml-2 p-1 mb-5">
          <a
            className={languages == "al" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("al")}
          >
            Albanian
          </a>
          <a
            className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("sr")}
          >
            Serbian
          </a>
          <a
            className={languages == "en" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("en")}
          >
            English
          </a>
        </div>

        {languages == "al" && (
          <>
            <input placeholder="Titulli" defaultValue={news.name_al} disabled />
            <textarea
              placeholder="Përmbajtja"
              defaultValue={news.desc_al}
              disabled
            />
            {/* <ReactQuill
              theme="snow"
              value={editordata}
              onChange={seteditordata}
              formats={formats}
              modules={modules}
            /> */}
          </>
        )}
        {languages == "sr" && (
          <>
            <input placeholder="Naslov" defaultValue={news.name_sr} disabled />
            <textarea
              placeholder="Sadrzaj"
              defaultValue={news.desc_sr}
              disabled
            />
          </>
        )}
        {languages == "en" && (
          <>
            <input placeholder="Title" value={news.name_en} disabled />
            <textarea placeholder="Content" value={news.desc_en} disabled />
          </>
        )}

        <button type="submit">Delete news</button>
      </form>
      {/* )} */}
    </div>
  );
};
export default DeleteNews;
