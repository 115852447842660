import { PieChartFilled } from '@ant-design/icons/lib/icons';
import React, { useState, useEffect } from "react";
import Categories from './Categories';
import Institutions from './Institutions';
import Employment from './Employment';
import Gender from './Gender';
import Municipalities from './Municipialities';
import Communities from './Communities';
import Education from './Education';
import axios from "../../axios";
import "./statistics.scss";


const currentDate = new Date(new Date().getTime()).toISOString().substring(0, 10);
const myDate = new Date();
const dateToday = new Date().toISOString();
const d = new Date(dateToday);
const palidhje = d.setMonth(d.getMonth() - 3)
const dateMonthAgo = new Date(palidhje).toISOString();

// console.log('muajt', dateMonthAgo);



const Statistics = () => {

  const [statistics, setStaticist] = useState([]);
  const [start, setStart] = useState(dateMonthAgo?.slice(0, 10));
  const [end, setEnd] = useState(dateToday?.slice(0, 10));
  const [search, setSearch] = useState(false);



  useEffect((e) => {
    axios
      .get(`statistics/${start}/${end}`)

      .then((res) => {
        setStaticist(res?.data);
        setSearch(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [search]);

  const dateStart = (e) => {
    setStart(e.target.value);
  }
  const dateEnd = (e) => {
    setEnd(e.target.value);
  }


  return (
    <div className="statistics text-left">
      <div className="page-title ">
        {/* {search && 
       <h1 > Statistics From: {start} to: {end}  </h1>
      } */}

        {/* <h1 > Statistics from: {start} to: {end}  </h1> */}
        <h1>Statistics</h1>
        <div className="container">
          <div className="row charts">
            <div className="col-sm-12 col-lg-12">
              <div className='date-set'>
                From:
                <input type={'date'} onChange={dateStart} max={currentDate} defaultValue={dateMonthAgo?.slice(0, 10)}></input>
                To:
                <input type={'date'} onChange={dateEnd} max={currentDate} defaultValue={dateToday?.slice(0, 10)}></input>
                <button onClick={() => setSearch(true)} >Search</button>
              </div>
            </div>
          </div>
          <div className='row '>
            <div className='col-sm-12 col-lg-12 muni-chart'>
              <Municipalities xona={start} xona2={end} xona3={search} xona4={() => setSearch(false)} />
            </div>
            <div className='col-sm-12 col-lg-6'>
              <Categories xona={start} xona2={end} xona3={search} xona4={() => setSearch(false)} />
            </div>
            <div className='col-sm-12 col-lg-6'>
              <Gender xona={start} xona2={end} xona3={search} xona4={() => setSearch(false)} />
            </div>
            <div className='col-sm-12 col-lg-6'>
              <Communities xona={start} xona2={end} xona3={search} xona4={() => setSearch(false)} />
            </div>
            <div className='col-sm-12 col-lg-6'>
              <Education xona={start} xona2={end} xona3={search} xona4={() => setSearch(false)} />
            </div>
            <div className='col-sm-12 col-lg-6'>
              <Institutions xona={start} xona2={end} xona3={search} xona4={() => setSearch(false)} />
            </div>
            <div className='col-sm-12 col-lg-6'>
              <Employment xona={start} xona2={end} xona3={search} xona4={() => setSearch(false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Statistics;
