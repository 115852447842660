import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
import "./about_us.scss";

const Delete = (props) => {
  const [aboutus, deleteaboutus] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [languages, editlanguages] = useState("al");
  const [isloading, setisloading] = useState(true);

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      editlanguages("al");
    }
    if (lang === "sr") {
      editlanguages("sr");
    }
    if (lang === "en") {
      editlanguages("en");
    }
  };

  useEffect(() => {
    axios
      .get("aboutus/all")
      .then((data) => setUsersData(data?.data))
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
    axios
      .get("aboutus/id?id=" + props.match.params.id)
      .then((data) => {
        deleteaboutus(data?.data);
        // console.log("userdata", data?.data);
        // setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("aboutus/?id=" + aboutus.id)
      .then((data) => {
        // console.log(data?.data);
        toast.success("Data has been deleted successfully!");
        window.history.back();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="about_us p-5 text-left">
      <div className="page-title">
        <h1>Delete About Us</h1>
      </div>
      {!isloading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="row ml-2 p-1 mb-5">
            <a
              className={languages == "al" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("al")}
            >
              Albanian
            </a>
            <a
              className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("sr")}
            >
              Serbian
            </a>
            <a
              className={languages == "en" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("en")}
            >
              English
            </a>
          </div>

          {languages == "al" && (
            <>
              <input type="text" defaultValue={aboutus.content_al} disabled />
            </>
          )}
          {languages == "sr" && (
            <>
              <input type="text" defaultValue={aboutus.content_sr} disabled />
            </>
          )}
          {languages == "en" && (
            <>
              <input type="text" defaultValue={aboutus.content_en} disabled />
            </>
          )}

          <button type="submit">Delete</button>
        </form>
      )}
    </div>
  );
};

export default Delete;
