import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";
import "./events.scss";

const DeleteEvents = (props) => {
  const [event, deleteEvent] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [languages, editlanguages] = useState("al");

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      editlanguages("al");
    }
    if (lang === "sr") {
      editlanguages("sr");
    }
    if (lang === "en") {
      editlanguages("en");
    }
  };

  useEffect(() => {
    axios
      .get("users/all")
      .then((data) => setUsersData(data?.data))
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });

    axios
      .get("events/id?id=" + props.match.params.id)
      .then((data) => {
        deleteEvent(data?.data);
        // console.log("userdata", data?.data);
        // setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .delete("events/?id=" + event.id)
      .then((data) => {
        // console.log(data?.data);
        window.history.back();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="events p-5 text-left">
      <div className="page-title">
        <h1>Delete Events</h1>
      </div>

      <div className="row mb-3 text">
        By clicking delete button you agree to delete events with the following
        data:
      </div>
      {/* {!isloading ? (
      ""
    ) : ( */}
      <form onSubmit={handleSubmit}>
        <label>From date:</label>
        <input
          type="date"
          defaultValue={event?.from_date?.slice(0, 10)}
          disabled
        />
        <label>To date:</label>
        <input
          type="date"
          defaultValue={event?.to_date?.slice(0, 10)}
          disabled
        />
        <input type="text" defaultValue={event.address} />
        <select defaultValue={event.created_by} disabled>
          {usersData?.map((el) => {
            return (
              <option value={el?.id} key={el?.id}>
                {el?.full_name}
              </option>
            );
          })}
        </select>

        <div className="row ml-2 p-1 mb-5">
          <a
            className={languages == "al" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("al")}
          >
            Albanian
          </a>
          <a
            className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("sr")}
          >
            Serbian
          </a>
          <a
            className={languages == "en" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("en")}
          >
            English
          </a>
        </div>

        {languages == "al" && (
          <>
            <input
              placeholder="Titulli"
              defaultValue={event.name_al}
              disabled
            />
            <textarea
              placeholder="Përmbajtja"
              defaultValue={event.desc_al}
              disabled
            />
          </>
        )}
        {languages == "sr" && (
          <>
            <input placeholder="Naslov" defaultValue={event.name_sr} disabled />
            <textarea
              placeholder="Sadrzaj"
              defaultValue={event.desc_sr}
              disabled
            />
          </>
        )}
        {languages == "en" && (
          <>
            <input placeholder="Title" value={event.name_en} disabled />
            <textarea placeholder="Content" value={event.desc_en} disabled />
          </>
        )}

        <button type="submit">Delete Event</button>
      </form>
      {/* )} */}
    </div>
  );
};

export default DeleteEvents;
