import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";

const DeleteLabelTranslations = (props) => {
  const [labeltranslations, addlabeltranslations] = useState({});
  const [languages, addlanguages] = useState({});
  const [labels, addlabels] = useState({});
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    axios
      .get("labels-translations/id?id=" + props.match.params.id)
      .then((data) => {
        addlabeltranslations(data.data);
        // console.log('user',data.data)
        setisloading(true);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("labels-translations/?id=" + labeltranslations.id)
      .then((data) => {
        toast.success(
          `Label ${labeltranslations.key} was deleted successfully`
        );
        // console.log(data);
        window.history.back();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };
  // console.log(languages);
  // console.log(labels);

  return (
    <div className="labels_translations p-5 text-left">
      <div className="page-title">
        <h1>Delete Label</h1>
      </div>
      <div className="row mb-3 text">
        By clicking delete button you agree to delete label with the following
        data:
      </div>
      {!isloading ? (
        ""
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Type</label>
          <input type="text" defaultValue={labeltranslations.type} disabled />
          <label>Key</label>
          <input type="text" defaultValue={labeltranslations.key} disabled />
          <label>Albanian</label>
          <input
            type="text"
            defaultValue={labeltranslations.content_al}
            disabled
          />
          <label>Serbian</label>
          <input
            type="text"
            defaultValue={labeltranslations.content_sr}
            disabled
          />
          <label>English</label>
          <input type="text" value={labeltranslations.content_en} disabled />

          <button type="submit">Delete Label</button>
        </form>
      )}
    </div>
  );
};
export default DeleteLabelTranslations;
