
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Player } from "video-react";
import { toast } from "react-toastify";
import axios from "../../axios";
// import "..ShowFilesModal/modal.scss"

const ShowDeleteModal = (props) => {
    const { id } = useParams();
    const [status, setStatus] = useState([]);
    const [item, setItem] = useState();
    const [delete1, setdelete1] = useState({});
    // console.log("first", props?.id)
    const { data } = props;
    // console.log("dataaa", data)
    const handleStatus = (e) => {
        const ud = Object.assign({}, item);
        ud.status_id = Number(e.target.value);
        // console.log('select', e.target.value);
        setItem(ud);
    };
    const handleDesc = (e) => {
        const ud = Object.assign({}, item);
        ud.description = e.target.value;
        setItem(ud);

    };
    const handleDate = (e) => {
        const ud = Object.assign({}, item);
        ud.date = e.target.value;
        setItem(ud);
    };
    let userId = window.localStorage.getItem("id");
    // console.log('userId', item);

    useEffect(() => {
        axios
            .get(`/case-statuses/id?id=` + props?.id)
            .then((res) => {
                setdelete1(res?.data);
                // console.log("delete1", res?.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const handleDelete = () => {
        axios
            .delete(`/case-statuses/id?id=` + props?.id)
            .then((data) => {
                toast.success(`User  was deleted successfully`);
                // console.log(data);

            })
            .catch((err) => {
                console.log(err);
                toast.error("Something went wrong!");
            });
    }


    useEffect(() => {
        const ud = Object.assign({}, item);
        ud.case_id = Number(id);
        ud.created_by = Number(userId);
        ud.id = props?.id;
        setItem(ud);
    }, [])

    useEffect(() => {
        axios
            .get(`/case-status/all`)
            .then((res) => {
                setStatus(res?.data);
            })
            .catch((err) => {
                console.log(err);
            });

    }, []);
    return (
        <>

            <div className="show_header">
                {/* <h3>Edit Status</h3> */}
                <button className=" myButton close_modal  fa fa-close " onClick={props.closeDeleteModal} />
            </div>
            <div className="statusModal">
                <div className="container editStatus">

                    <h5 className="mt-4 mb-4">
                        Delete status
                    </h5>

                    <form className="form" onSubmit={handleDelete}>
                        <select className="form-control mb-2" name="status" onChange={handleStatus} disabled >
                            {status?.map((el) => {
                                return (
                                    <option value={el.id} selected={delete1?.status_id === el.id ? true : ""}>{el.status_al}</option>

                                );
                            })}
                        </select>
                        <textarea className="form-control mb-2" onChange={handleDesc} defaultValue={delete1?.description} disabled></textarea>
                        <input type="date" className="form-control mb-2" onChange={handleDate} defaultValue={delete1?.date?.slice(0, 10)} disabled></input>
                        <button type="submit" className="form-control mb-2 btnEdit">Delete</button>
                    </form>
                </div>


            </div>

        </>
    )
}

export default ShowDeleteModal