import React, { useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";

const AddLinks = () => {
  const [linksdata, addlinksdata] = useState({});
  const [languages, addlanguages] = useState("al");
  const [isloading, setisloading] = useState(false);

  const name_al = (e) => {
    const ud = Object.assign({}, linksdata);
    ud.name_al = e.target.value;
    addlinksdata(ud);
  };
  const name_sr = (e) => {
    const ud = Object.assign({}, linksdata);
    ud.name_sr = e.target.value;
    addlinksdata(ud);
  };
  const name_en = (e) => {
    const ud = Object.assign({}, linksdata);
    ud.name_en = e.target.value;
    addlinksdata(ud);
  };
  const url = (e) => {
    const ud = Object.assign({}, linksdata);
    ud.url = e.target.value;
    addlinksdata(ud);
  };
  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("links", linksdata)
      .then((data) => {
        // console.log(data);
        toast.success("Links has been added successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <div className="links p-5 text-left">
      <div className="page-title">
        <h1>Add Links</h1>
      </div>
      {/* {!isloading ? '' : */}
      <form onSubmit={handleSubmit}>
        <input type="text" onChange={url} placeholder="Url"  className="mt-4"/>

        <div className="row ml-2 p-1 mb-2">
          <a
            className={languages == "al" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("al")}
          >
            Albanian
          </a>
          <a
            className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("sr")}
          >
            Serbian
          </a>
          <a
            className={languages == "en" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("en")}
          >
            English
          </a>
        </div>

        {languages == "al" && (
          <>
            <input
              placeholder="Emri"
              onChange={name_al}
              defaultValue={linksdata.name_al}
            />
          </>
        )}
        {languages == "sr" && (
          <>
            <input
              placeholder="име"
              onChange={name_sr}
              defaultValue={linksdata.name_sr}
            />
          </>
        )}
        {languages == "en" && (
          <>
            <input
              placeholder="Name"
              onChange={name_en}
              defaultValue={linksdata.name_en}
            />
          </>
        )}

        <button type="submit">Add Link</button>
      </form>
      {/* } */}
    </div>
  );
};

export default AddLinks;
