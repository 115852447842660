
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Player } from "video-react";
import axios from "../../axios";
import "./modal.scss"

const ShowFilesModal = (props) => {
  const { id } = useParams();
  const [files, setFiles] = useState([]);


  useEffect(() => {
    axios
      .get("report-cases/caseFiles/" + id)
      .then((res) => {
        setFiles(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="showfiles_modal">
      <div className="show_header">
        <button className=" myButton close_modal  fa fa-close " onClick={props.closeModal} />
      </div>
      <div className="container">
        <h3 className="text-dark">Files</h3>
        {/* <div className="row justify-content-end"> */}
        <div className="myImages">
          <div className="col-12  mt-5">
            {/* {console.log("fotott", files)} */}
            {files?.images?.length === 0 && files?.videos?.length === 0 ? (<><p className="text-left text-dark">No uploaded files</p></>) : (<>
            <div className="row">
              {files?.images?.map((el) => {
                return (
                  <>
                  <div className="col-md-6 mb-5">

                    <div className="show_images">
                      <img
                      className="w-100" style={{height:"13em"}}
                        src={`${process.env.REACT_APP_UPLOADS + el}`}
                        />
                    </div>
                        </div>
                  </>
                );
              })}</div>
              </>)}
          </div>

</div>
        <div className="video ">
          <div className="col-12">

            {files?.videos?.map((el) => {
              return (
                <>
                  <video className="h-100 w-100" controls>
            <source src={`${process.env.REACT_APP_UPLOADS + el}`} type="video/mp4"/>
                Your browser does not support the video tag.
                </video>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
    // </div>
  )
}

export default ShowFilesModal