import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import Search from "../search/Search";
import ReactPaginate from "react-paginate";
import Loader from "../loader/Loader";
import { toast } from "react-toastify";


const DocumentsPublications = () => {
  const [docData, setDocData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 5;
  const productsPerList = 50;
  const pagesVisited = pageNumber * productsPerPage;
  let displayeventdata = [];
  const pageCount = Math.ceil(filtered.length / productsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    axios
      .get("documents_publications/all")
      .then((res) => {
        setDocData(res?.data);
        setFiltered(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);
  

  return (
    
    <div className="events p-5 text-left">
      <div className="page-title ">
        <h1>Documents and publications</h1>
      </div>
      <div className="row ml-n4 search">
        <div className="col-md-9 col-sm-12">
          <Search
            data={docData}
            title="documents_publications"
            filtered={setFiltered}
            setPageNumber={setPageNumber}
          />
        </div>
        <div className="col-md-3 col-sm-12">
          <Link to="/documents_publications/add" className="myButton">
            Add New Document
          </Link>
        </div>
      </div>

      <div className="listimi-tabela">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="listimi-head">
              <div className="row ">
                <div className="col-1">ID</div>
                <div className="col-2">Title</div>
                <div className="col-4">Description:</div>
                <div className="col-1">ALB</div>
                <div className="col-1">SRB</div>
                <div className="col-1">ENG</div>
                <div className="col-2 hideme"> </div>
              </div>
            </div>

            {
              (displayeventdata = filtered
                ?.slice(pagesVisited, pagesVisited + productsPerPage)
                ?.map((filtered) => {
                  return (
                    <div className="listimi showme" key={filtered.id}>
                      <div className="row ">
                        <div className="col-1">{filtered.id} </div>

                        <div className="col-2">{filtered.title_al?.slice(0,20)+"..."} </div>
                        <div className="col-4" ><p dangerouslySetInnerHTML={{ __html: filtered?.description_al?.slice(0, 50)+"..." }}></p> </div>

                        <div className="col-1">
                          {filtered.description_al === null ||
                          filtered.description_al === "" ? (
                            <i className="far fa-times-circle text-danger"></i>
                          ) : (
                            <i className="far fa-check-circle text-success"></i>
                          )}{" "}
                        </div>

                        <div className="col-1">
                          {filtered.description_sr === null ||
                          filtered.description_sr === "" ? (
                            <i className="far fa-times-circle text-danger"></i>
                          ) : (
                            <i className="far fa-check-circle text-success"></i>
                          )}{" "}
                        </div>

                        <div className="col-1">
                          {filtered.description_en === null ||
                          filtered.description_en === "" ? (
                            <i className="far fa-times-circle text-danger"></i>
                          ) : (
                            <i className="far fa-check-circle text-success"></i>
                          )}{" "}
                        </div>

                        <div className="col-1 delete">
                          <Link
                            className={"fa fa-trash"}
                            to={"/documents_publications/delete/" + filtered.id}
                          ></Link>
                        </div>

                        <div className="col-1 edit">
                          <Link
                            className={"fa fa-edit"}
                            to={"/documents_publications/edit/" + filtered.id}
                          ></Link>
                        </div>
                      </div>
                    </div>
                  );
                }))
            }
          </>
        )}
      </div>

      <div className="row mt-5">
        <div className="col-12">
          {docData.length >= productsPerPage ? (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentsPublications;
