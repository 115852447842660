import React, { useState, useEffect } from "react";
import Loader from "../loader/Loader";
import { Player } from "video-react";
import axios from "../../axios";
import { useParams, Link } from "react-router-dom";
import "./showfiles.scss";

const ShowFiles = (props) => {
  const [files, setFiles] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    axios
      .get("report-cases/caseFiles/" + id)
      .then((res) => {
        setFiles(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log("filesss", files);

  return (
    <div className="show_files">
      <div className="page-title">
        <h3>Files</h3>
      </div>
      {
        /* {!isloading ? <Loader /> : <div></div>} */
        <div className="row w-100 pl-5">
          <div className="col-6">
            {files?.images?.map((el) => {
              return (
                <img
                  src={`${process.env.REACT_APP_UPLOADS + el}`}
                  width="300px"

                />
              );
            })}
          </div>
          <div className="col-6 video">
            {files?.videos?.map((el) => {
              return (
                <Player>
                  <source src={`${process.env.REACT_APP_UPLOADS + el}`} />
                </Player>
              );
            })}
          </div>
        </div>
      }
    </div>
  );
};

export default ShowFiles;
