import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditLegislations = (props) => {
  const [legislationsdata, editlegislationsdata] = useState([]);
  const [userroles, adduserroles] = useState([]);
  const [languages, addlanguages] = useState("al");
  const [isloading, setisloading] = useState(false);
  const [content, setContent] = useState("");
  const [contentsr, setContentsr] = useState("");
  const [contenten, setContenten] = useState("");
  const [laws, setlaws] = useState([]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  useEffect(() => {
    axios
      .get("legislations/id?id=" + props.match.params.id)
      .then((data) => {
        editlegislationsdata(data?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });

    axios
      .get("users/all")
      .then((data) => {
        adduserroles(data?.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const name_al = (e) => {
    const ud = Object.assign({}, legislationsdata);
    ud.name_al = e.target.value;
    editlegislationsdata(ud);
  };
  const name_sr = (e) => {
    const ud = Object.assign({}, legislationsdata);
    ud.name_sr = e.target.value;
    editlegislationsdata(ud);
  };
  const name_en = (e) => {
    const ud = Object.assign({}, legislationsdata);
    ud.name_en = e.target.value;
    editlegislationsdata(ud);
  };

  const desc_al = (value) => {
    setContent(value);
  };
  const desc_sr = (value) => {
    setContentsr(value);
  };
  const desc_en = (value) => {
    setContenten(value);
  };

  const url_al = (e) => {
    const ud = Object.assign({}, legislationsdata);
    ud.url_al = e.target.value;
    editlegislationsdata(ud);
  };
  const url_sr = (e) => {
    const ud = Object.assign({}, legislationsdata);
    ud.url_sr = e.target.value;
    editlegislationsdata(ud);
  };
  const url_en = (e) => {
    const ud = Object.assign({}, legislationsdata);
    ud.url_en = e.target.value;
    editlegislationsdata(ud);
  };
  const leg_date = (e) => {
    const ud = Object.assign({}, legislationsdata);
    ud.leg_date = e.target.value;
    editlegislationsdata(ud);
  };
  // const created_at = (e) => {
  //   const ud = Object.assign({}, legislationsdata);
  //   ud.created_at = e.target.value;
  //   editlegislationsdata(ud);
  // };
  const created_by = (e) => {
    const ud = Object.assign({}, legislationsdata);
    ud.created_by = e.target.value;
    editlegislationsdata(ud);
  };
  const lawstatus = (e) => {
    const ud = Object.assign({}, legislationsdata);
    ud.lawstatus = e.target.value;
    editlegislationsdata(ud);
  };

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    legislationsdata.desc_al = content;
    legislationsdata.desc_en = contenten;
    legislationsdata.desc_sr = contentsr;

    axios
      .put("legislations", legislationsdata)
      .then((res) => {
        // console.log(res?.data);
        toast.success("Legislation has been edited successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  // console.log("lefissdjkga", legislationsdata);
  return (
    <div className="legislation p-5 text-left">
      <div className="page-title">
        <h1>Edit Legislations</h1>
      </div>
      {/* {!isloading ? '' : */}
      <form onSubmit={handleSubmit}>
        <label>Legislation date:</label>
        <input
          type="date"
          onChange={leg_date}
          defaultValue={legislationsdata?.leg_date?.slice(0, 10)}
        />
        {/* <label>Created at:</label>
        <input
          type="date"
          onChange={created_at}
          defaultValue={legislationsdata?.created_at?.slice(0, 10)}
        /> */}

        <select onChange={created_by} value={legislationsdata?.created_by}>
          {/* <option value="" disabled selected>
            Created By
          </option> */}
          {userroles?.map((el, index) => {
            return (
              <option value={el?.id} key={index} selected>
                {el?.full_name}
              </option>
            );
          })}
        </select>
        <select onChange={lawstatus} defaultValue={legislationsdata?.lawstatus}>
          <option value={null} disabled></option>
          <option value={"National"}>National</option>
          <option value={"International"}>International</option>
        </select>

        <div className="row ml-2 p-1 mb-5">
          <a
            className={languages == "al" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("al")}
          >
            Albanian
          </a>
          <a
            className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("sr")}
          >
            Serbian
          </a>
          <a
            className={languages == "en" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("en")}
          >
            English
          </a>
        </div>

        {languages == "al" && (
          <>
            <input
              placeholder="Titulli"
              onChange={name_al}
              defaultValue={legislationsdata.name_al}
            />
            <ReactQuill
              theme="snow"
              defaultValue={legislationsdata.desc_al}
              placeholder={"Write something awesome..."}
              onChange={desc_al}
              formats={formats}
              modules={modules}
            />
            <input
              className="url"
              placeholder="Url_al"
              onChange={url_al}
              defaultValue={legislationsdata.url_al}
            />
          </>
        )}
        {languages == "sr" && (
          <>
            <input
              placeholder="Naslov"
              onChange={name_sr}
              defaultValue={legislationsdata.name_sr}
            />
            <ReactQuill
              theme="snow"
              defaultValue={legislationsdata.desc_sr}
              placeholder={"Write something awesome..."}
              onChange={desc_sr}
              formats={formats}
              modules={modules}
            />
            <input
              className="url"
              placeholder="Url_sr"
              onChange={url_sr}
              defaultValue={legislationsdata.url_sr}
            />
          </>
        )}
        {languages == "en" && (
          <>
            <input
              placeholder="Title"
              onChange={name_en}
              defaultValue={legislationsdata.name_en}
            />
            <ReactQuill
              theme="snow"
              defaultValue={legislationsdata.desc_en}
              placeholder={"Write something awesome..."}
              onChange={desc_en}
              formats={formats}
              modules={modules}
            />
            <input
              className="url"
              placeholder="Url_en"
              onChange={url_en}
              defaultValue={legislationsdata.url_en}
            />
          </>
        )}

        <button type="submit">Edit Legislation</button>
      </form>
      {/* } */}
    </div>
  );
};

export default EditLegislations;
