import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./labels_translations.scss";
import axios from "../../axios";
import Search from "../search/Search";
import ReactPaginate from "react-paginate";
import Loader from "../loader/Loader";
import { toast } from "react-toastify";

const LabelTranslations = () => {
  const [labeltranslations, setlabeltranslations] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 5;
  const productsPerList = 50;
  const pagesVisited = pageNumber * productsPerPage;
  let displayLabelTranslations = [];
  const pageCount = Math.ceil(filtered.length / productsPerPage);

  const changePage = ({ selected }) => {
    // console.log(selected);
    setPageNumber(selected);
  };

  useEffect(() => {
    axios
      .get("labels-translations/all")
      .then((res) => {
        setlabeltranslations(res?.data);
        setFiltered(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  return (
    <div className="labels_translations p-5 text-left">
      <div className="page-title ">
        <h1>Label Translation Management</h1>
      </div>
      <div className="row ml-n4 search">
        <div className="col-md-9">
          <Search
            data={labeltranslations}
            title="translations"
            filtered={setFiltered}
            setPageNumber={setPageNumber}
          />
        </div>
        <div className="col-md-3">
          <Link to="/labeltranslations/add" className="myButton">
            Add New Label
          </Link>
        </div>
      </div>
      {/* <div className="row">
        <Link to="/labeltranslations/add" className="myButton">
          Add New Label
        </Link>
      </div> */}
      <div className="listimi-tabela">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="listimi-head">
              <div className="row ">
                <div className="col-1">id</div>
                <div className="col-1">Type</div>
                <div className="col-2">key</div>
                <div className="col-2">Name ALB</div>
                <div className="col-2">Name SRB</div>
                <div className="col-2">Name ENG</div>
                {/* <div className='col-1'>{filtered.def_lang} </div> */}

                <div className="col-1 hideme"> </div>
                {/* <div className='col-1 hideme'> </div> */}
              </div>
            </div>{" "}
            {
              (displayLabelTranslations = filtered
                ?.slice(pagesVisited, pagesVisited + productsPerPage)
                ?.map((filtered) => {
                  return (
                    <div className="listimi showme" key={filtered.id}>
                      <div className="row ">
                        <div className="col-1">{filtered.id} </div>
                        <div className="col-1">{filtered.type} </div>
                        <div className="col-2 text-break">{filtered.key} </div>
                        <div className="col-2">{filtered.content_al} </div>
                        <div className="col-2">{filtered.content_sr} </div>
                        <div className="col-2">{filtered.content_en} </div>
                        {/* <div className='col-1'>{filtered.def_lang} </div> */}
                        <div className="col-1 delete">
                          <Link
                            className={"fa fa-trash"}
                            to={"/labeltranslations/delete/" + filtered.id}
                          ></Link>
                        </div>
                        <div className="col-1 edit">
                          <Link
                            className={"fa fa-edit"}
                            to={"/labeltranslations/edit/" + filtered.id}
                          ></Link>
                        </div>
                      </div>
                    </div>
                  );
                }))
            }
          </>
        )}
      </div>
      <div className="row mt-5">
        <div className="col-12">
          {labeltranslations.length >= productsPerPage ? (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default LabelTranslations;
