import React, { useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import "./community.scss";

const AddCommunity = () => {
  const [communityData, addCommunityData] = useState({});
  const [languages, addlanguages] = useState("al");

  const name_al = (e) => {
    const ud = Object.assign({}, communityData);
    ud.name_al = e.target.value;
    addCommunityData(ud);
  };

  const name_sr = (e) => {
    const ud = Object.assign({}, communityData);
    ud.name_sr = e.target.value;
    addCommunityData(ud);
  };

  const name_en = (e) => {
    const ud = Object.assign({}, communityData);
    ud.name_en = e.target.value;
    addCommunityData(ud);
  };

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("community", communityData)
      .then((data) => {
        // console.log(data);
        toast.success("Community has been added successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <>
      <div className="community p-5 text-left">
        <div className="page-title">
          <h1>Add Community</h1>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row ml-2 p-1 mb-5">
            <a
              className={languages == "al" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("al")}
            >
              Albanian
            </a>
            <a
              className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("sr")}
            >
              Serbian
            </a>
            <a
              className={languages == "en" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("en")}
            >
              English
            </a>
          </div>

          {languages == "al" && (
            <>
              <input
                placeholder="Emri"
                onChange={name_al}
                defaultValue={communityData.name_al}
              />
            </>
          )}
          {languages == "sr" && (
            <>
              <input
                placeholder="име"
                onChange={name_sr}
                defaultValue={communityData.name_sr}
              />
            </>
          )}
          {languages == "en" && (
            <>
              <input
                placeholder="Name"
                onChange={name_en}
                defaultValue={communityData.name_en}
              />
            </>
          )}

          <button type="submit">Add Community</button>
        </form>
      </div>
    </>
  );
};

export default AddCommunity;
