import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axios from "../../axios";
import "./case_discussion.scss";
import Loader from "../loader/Loader";

const CaseDiscussion = (props) => {
  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const productsPerPage = 6;
  const productsPerList = 50;
  const pagesVisited = pageNumber * productsPerPage;
  let displaynewcases = [];
  const pageCount = Math.ceil(props?.xona.length / productsPerPage);

  useEffect(() => {
    setIsLoading(false)

  }, []);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  if (isLoading === true) {
    return <Loader />;
  }
  return (
    <div className="case-discussion">
      <div className="page-title">
        <h1> Case Discussion</h1>
      </div>
      <div className="row w-100 mt-4 cases">
        {
          (displaynewcases = props?.xona
            ?.slice(pagesVisited, pagesVisited + productsPerPage)
            ?.map((el) => {
              return (
                <div className="new-case" key={el.id}>
                  <div className="row case">
                    <b>Case:</b>
                    {el.case_al?.length >= 60 ?
                      <span>
                        {showMore ? el.case_al : el.case_al?.slice(0, 60) + "..."}
                        <a className="btn" onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</a>
                      </span> : <span>{el.case_al}</span>}
                  </div>

                  <div className="row type">
                    <b>Report Type:</b> {el.cat_en}
                  </div>

                  <div className="row municipality">
                    <b>Municipality:</b> {el.municipality_al}
                  </div>

                  <div className="row agency">
                    <b>Agency:</b> {el.gov_al}
                  </div>

                  <div className="row contact">
                    <b>Contact:</b> {el.phone}
                  </div>

                  <div className="row contact">
                    <b>Email:</b> {el.email}
                  </div>

                  <div className="row actions ">
                    <Link to={"/report-cases/" + el.id}>
                      {" "}
                      <button className="translate">Discussion</button>
                    </Link>
                  </div>
                </div>
              );
            }))
        }
      </div>
      <div className="row mt-5 ">
        <div className="col-12">
          {props?.xona2?.length >= productsPerPage ? (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseDiscussion;
