import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import axios from "../../axios";
import * as XLSX from 'xlsx';


ChartJS.register(
  ArcElement,
  Legend,
  Tooltip
)

const Gender = (props) => {

  const [byGender, setbyGender] = useState([]);

  useEffect(() => {
    axios
      .get(`statistics/byGender/${props?.xona}/${props?.xona2}`)
      .then((res) => {
        setbyGender(res?.data);
        props?.xona4();
        // console.log("Gender", res?.data)             
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props?.xona3]);

  let gender = [];
  let number = [];

  byGender?.map(el => {
    gender.push(el.gender);
    number.push(el.number);
  });
  var data = {
    type: 'pie',
    labels: gender,
    datasets: [{
      label: 'Gender Chart',
      data: number,
      backgroundColor: [
        'rgb(247,0,1)', //Red-Kuq
        'rgb(241,108,34)', //Orange-Portokall
        'rgb(14,77,146)', //Blue-Kalter
        'rgb(73,168,63)', //Green-Gjelbert
      ],
      hoverOffset: 4,
      borderWidth: 1
    }]
  };


  var options = {
    maintainAspectRatio: false,
    borderRadius: 10,
    // scales: {
    //     y: {
    //         beginAtZero: true
    //     }
    // },
    legend: {
      labels: {
        fontSize: 26
      }
    }
  }

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(byGender);
    XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 });
    XLSX.utils.sheet_add_aoa(worksheet, [['Nga data:', props?.xona]], { origin: -1 });
    XLSX.utils.sheet_add_aoa(worksheet, [['Deri në datë :', props?.xona2]], { origin: -1 });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Gender');
    XLSX.writeFile(workbook, 'gender.xlsx');
};

  return (
    <>
    <div className="chart-title pt-5">
      <h3>Gender Chart </h3>
      <button onClick={() => exportToExcel(props?.xona, props?.xona2)} className="excel-btn"><i class="fas fa-file-excel pr-2"></i>Export to Excel</button>
    </div>

      <div>
        <Pie
          data={data}
          // width={300}
          height={300}
          options={options}
        />
      </div>
    </>

  )

}

export default Gender;