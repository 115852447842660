import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";

const DeleteCommunity = (props) => {
  const [community, deleteCommunity] = useState({});
  const [languages, editlanguages] = useState("al");

  useEffect(() => {
    axios
      .get("community/id?id=" + props.match.params.id)
      .then((data) => {
        deleteCommunity(data?.data);
        // console.log("userdata", data?.data);
        // setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      editlanguages("al");
    }
    if (lang === "sr") {
      editlanguages("sr");
    }
    if (lang === "en") {
      editlanguages("en");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("community/id?id=" + community.id)
      .then((data) => {
        // console.log(data?.data);
        window.history.back();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="community p-5 text-left">
        <div className="page-title">
          <h1>Delete Community</h1>
        </div>

        <div className="row mb-3 text">
          By clicking delete button you agree to delete community with the
          following data:
        </div>

        {/* {!isloading ? '' : */}
        <form onSubmit={handleSubmit}>
          <div className="row ml-2 p-1 mb-5">
            <a
              className={languages == "al" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("al")}
            >
              Albanian
            </a>
            <a
              className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("sr")}
            >
              Serbian
            </a>
            <a
              className={languages == "en" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("en")}
            >
              English
            </a>
          </div>

          {languages == "al" && (
            <>
              <input defaultValue={community.name_al} disabled />
            </>
          )}
          {languages == "sr" && (
            <>
              <input defaultValue={community.name_sr} disabled />
            </>
          )}
          {languages == "en" && (
            <>
              <input value={community.name_en} disabled />
            </>
          )}

          <button type="submit">Delete Community</button>
        </form>
        {/* } */}
      </div>
    </>
  );
};

export default DeleteCommunity;
