import React, { useState, useEffect } from "react";
import AwaitingDiscussions from "./awaiting_discussion";
import NewCase from "./new_case";
import "./dashboard.scss";
import axios from "../../axios";
import Loader from "../loader/Loader";
const Dashboard = () => {
  const [isReport, setisReport] = useState(true);
  const [newcase, setnewcase] = useState([]);
  const [isDiscussion, setisDiscussion] = useState(false);
  const [awaitindiscussion, setawaitingdiscussion] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    axios
      .get("report-cases/casesWithoutDiscussion")
      .then((res) => {
        setnewcase(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("report-cases/caseFromUser/" + window.localStorage.getItem("id"))
      .then((res) => {
        setawaitingdiscussion(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 300);

  }, []);

  const showReports = (e) => {
    setisDiscussion(false);
    setisReport(true);
  };

  const showDiscussions = (e) => {
    setisReport(false);
    setisDiscussion(true);
  };
  if (isLoading === true) {
    return <Loader />;
  }
  return (
    <div className="dashboard p-5 text-left">
      <div className="page-title">
        <h1> Dashboard </h1>
      </div>

      <div className={"row cases"}>
        <button
          className={
            isDiscussion ? "modal-button active-modal" : "modal-button"
          }
          onClick={showDiscussions}
        >
          Awaiting Case Discussions
          <span className="count">{awaitindiscussion.length}</span>
        </button>
        <button
          className={isReport ? "modal-button active-modal" : "modal-button"}
          onClick={showReports}
        >
          New Reported Cases<span className="count">{newcase.length}</span>
        </button>

      </div>
      <div className="row w-100 mt-4 cases">
        {isDiscussion && (awaitindiscussion.length > 0 ? (
          <div className="reports-dashboard">
            <AwaitingDiscussions />
          </div>
        ) : (
          <p>
            There are 0 awaiting discussions!
          </p>
        ))}

        {isReport && (newcase.length > 0 ? (
          <div className="reports-dashboard">
            <NewCase />
          </div>
        ) : (
          <p>
            There are 0 new cases!
          </p>
        ))}

        {/* 

        {isDiscussion && (
          <div className="reports-dashboard">
            <AwaitingDiscussions />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Dashboard;
