import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};


const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];


const AddDocumentsPublications = () => {

    const [addDocuments, setAddDocuments] = useState({
        title_al: '',
        title_en: '',
        title_sr: '',
        description_al: '',
        description_en: '',
        description_sr: '',
        pdf_link_al: null,
        pdf_link_en: null,
        pdf_link_sr: null,
      });


    const [languages, addlanguages] = useState("al");
    const [content, setContent] = useState("");
    const [contentsr, setContentsr] = useState("");
    const [contenten, setContenten] = useState("");


    const handleChange = (e) => {
        setAddDocuments({
          ...addDocuments,
          [e.target.name]: e.target.value,
        });
      };
    
      const handleFileChange = (e, language) => {
        let fileKey;
        switch (language) {
          case 'al':
            fileKey = 'pdf_link_al';
            break;
          case 'en':
            fileKey = 'pdf_link_en';
            break;
          case 'sr':
            fileKey = 'pdf_link_sr';
            break;
          default:
            fileKey = 'pdf_link_al';
        }
      
        setAddDocuments({
          ...addDocuments,
          [fileKey]: e.target.files[0], 
        });
      };
    
      const HandleSubmit = (e) => {
        e.preventDefault();

        addDocuments.description_al = content;
        addDocuments.description_en = contenten;
        addDocuments.description_sr = contentsr;
      
        const formData = new FormData();
        formData.append('title_al', addDocuments.title_al);
        formData.append('title_en', addDocuments.title_en);
        formData.append('title_sr', addDocuments.title_sr);
        formData.append('description_al', content);
        formData.append('description_en', contenten);
        formData.append('description_sr', contentsr);
        formData.append('pdf_link_al', addDocuments.pdf_link_al);
        formData.append('pdf_link_en', addDocuments.pdf_link_en);
        formData.append('pdf_link_sr', addDocuments.pdf_link_sr);
      
        axios.post('/documents_publications', formData)
          .then(response => {
            console.log(response.data);
            toast.success("Document has been added successfully!");
            window.history.back();
          })
          .catch(error => {
            console.error('Error uploading document:', error);
          });
      };

      const description_al = (value) => {
        setContent(value);
      };
      const description_sr = (value) => {
        setContentsr(value);
      };
      const description_en = (value) => {
        setContenten(value);
      };


      const language = (lang) => {
        if (lang === "al") {
          addlanguages("al");
        }
        if (lang === "sr") {
          addlanguages("sr");
        }
        if (lang === "en") {
          addlanguages("en");
        }
      };
    

  return (
    <div className="legislation p-5 text-left">

      <div className="page-title">
        <h1>Add documents and publications</h1>
      </div>

      <form onSubmit={HandleSubmit}>
       
        <div className="row ml-2 p-1 mb-5">
          <a
            className={languages == "al" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("al")}
          >
            Albanian
          </a>
          <a
            className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("sr")}
          >
            Serbian
          </a>
          <a
            className={languages == "en" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("en")}
          >
            English
          </a>
        </div>

        {languages == "al" && (
          <>
            <input
              placeholder="Titulli AL"
              name="title_al"
              onChange={handleChange}
              defaultValue={addDocuments.title_al}
            />
            <ReactQuill
              theme="snow"
              value={content}
              placeholder={"Write something awesome..."}
              name="description_al"
              onChange={description_al}
              modules={modules}
            />
             <input
               className="mt-2"
                type="file"
                onChange={(e) => handleFileChange(e, 'al')}
            />
          </>
        )}
        {languages == "sr" && (
          <>
            <input
              placeholder="Titulli SR"
              name="title_sr"
              onChange={handleChange}
              defaultValue={addDocuments.title_sr}
            />
            <ReactQuill
              theme="snow"
              value={contentsr}
              placeholder={"Write something awesome..."}
              name="description_sr"
              onChange={description_sr}
              modules={modules}
            />
        
            <input
                className="mt-2"
                type="file"
                onChange={(e) => handleFileChange(e, 'sr')}
            />
          </>
        )}

        {languages == "en" && (
          <>
            <input
              placeholder="Titulli EN"
              name="title_en"
              onChange={handleChange}
              defaultValue={addDocuments.title_en}
            />
            <ReactQuill
              theme="snow"
              value={contenten}
              placeholder={"Write something awesome..."}
              name="description_en"
              onChange={description_en}
              modules={modules}
            />
            <input
               className="mt-2"
                type="file"
                onChange={(e) => handleFileChange(e, 'en')}
            />
          </>
        )}
        <button type="submit">Add Legislation</button>
      </form>
    </div>
  );
};

export default AddDocumentsPublications