import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../loader/Loader";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const AddNews = (props) => {
  const [newsdata, addnewsdata] = useState({});
  const [userroles, adduserroles] = useState([]);
  const [languages, addlanguages] = useState("al");
  const [isloading, setisloading] = useState(true);
  const [content, setContent] = useState("");
  const [contenten, setContenten] = useState("");
  const [contentsr, setContentsr] = useState("");
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [user, setUser] = useState();

  useEffect(() => {
    axios
      .get("users/" + window.localStorage.getItem("id"))
      .then((data) => {
        setUser(data?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });

    axios
      .get("users/all")
      .then((data) => {
        adduserroles(data?.data);
        setisloading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const news_date = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.news_date = e.target.value;
    addnewsdata(ud);
  };

  const name_al = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.name_al = e.target.value;
    addnewsdata(ud);
  };

  const name_sr = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.name_sr = e.target.value;
    addnewsdata(ud);
  };

  const name_en = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.name_en = e.target.value;
    addnewsdata(ud);
  };

  const desc_al = (value) => {
    setContent(value);
    console.log(content);
  };

  const desc_en = (value) => {
    setContenten(value);
    console.log(content);
  };

  const desc_sr = (value) => {
    setContentsr(value);
    console.log(content);
  };

  const created_by = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.created_by = e.target.value;
    addnewsdata(ud);
  };

  const language = (lang) => {
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };

  const pic_url = (e) => {
    let img = e.target.files[0];
    setImage(img);
  };

  const video_url = (e) => {
    let vid = e.target.files[0];
    setVideo(vid);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    
    formData.append("desc_al", content || "");
    formData.append("desc_en", contenten || "");
    formData.append("desc_sr", contentsr || "");
    formData.append("news_date", newsdata.news_date);
    formData.append("created_by", newsdata.created_by );
    formData.append("name_al", newsdata.name_al || "");
    formData.append("name_sr", newsdata.name_sr || "");
    formData.append("name_en", newsdata.name_en || "");
    if (image) formData.append("image", image); 
    if (video) formData.append("video", video); 

    try {
      const response = await axios.post("/news", formData);
      toast.success("News has been added successfully!");
      window.history.back();
    } catch (err) {
      toast.error("Something went wrong!");
      console.log(err);
    }
  };

  return (
    <div className="news p-5 text-left">
      <div className="page-title">
        <h1>Add News</h1>
      </div>
      {isloading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Upload photo:</label> 
          <input type="file" accept="image/*" onChange={pic_url} />
          <label>Upload video:</label> 
          <input type="file" accept="video/*" onChange={video_url} />
          <input
            type="date"
            onChange={news_date}
            required
            title="This field is required"
            placeholder="From Date"
          />

          <select required title="This field is required" onChange={created_by}>
            <option value="" disabled selected>
              Created By
            </option>
            <option value={user?.id}>{user?.full_name}</option>
          </select>

          <div className="row ml-2 p-1 mb-5">
            <a
              className={languages === "al" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("al")}
            >
              Albanian
            </a>
            <a
              className={languages === "sr" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("sr")}
            >
              Serbian
            </a>
            <a
              className={languages === "en" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("en")}
            >
              English
            </a>
          </div>

          {languages === "al" && (
            <>
              <input
                placeholder="Titulli"
                onChange={name_al}
                defaultValue={newsdata.name_al}
              />
              <ReactQuill
                theme="snow"
                value={content}
                placeholder={"Write something awesome..."}
                onChange={desc_al}
                formats={formats}
                modules={modules}
              />
            </>
          )}
          {languages === "sr" && (
            <>
              <input
                placeholder="Naslov"
                onChange={name_sr}
                defaultValue={newsdata.name_sr}
              />
              <ReactQuill
                theme="snow"
                value={contentsr}
                placeholder={"Write something awesome..."}
                onChange={desc_sr}
                formats={formats}
                modules={modules}
              />
            </>
          )}
          {languages === "en" && (
            <>
              <input
                placeholder="Title"
                onChange={name_en}
                defaultValue={newsdata.name_en}
              />
              <ReactQuill
                theme="snow"
                value={contenten}
                placeholder={"Write something awesome..."}
                onChange={desc_en}
                formats={formats}
                modules={modules}
              />
            </>
          )}

          <button type="submit">Add News</button>
        </form>
      )}
    </div>
  );
};

export default AddNews;
